.collapsableTable {
    background: var(--primary-bg-light-color);
    border: 1px solid #D5D5D5;
    border-radius: 10px;
}

.collapseFirstHeaderItem {
    position: relative;
    flex-shrink: 0;
    transform: translateX(-12px);
    &::before, &::after {
        content:  ' ';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #848484;
        border-radius: 2px;
        transition: all .3s ease;
    }
    &::before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 1px;
    }
    &::after {
        top: 50%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        opacity: 0;
        height: 0;
    }
    &Show {
        &::after {
            height: 8px;
            opacity: 1;
        }
    }
}
.collapsBoard {
  }
  
  .blue {
    background: var(--primary-bg-light-color);
  }
  
  .gray {
    background: var(--gray-bg-color);
  }
  
  .icon {
    fill: var(--black-color);
    stroke: var(--black-color);
    transform: rotate(-90deg);
    width: 100%;
    transition: all .3s ease-in-out;
  }
  
  .iconContainer {
    width: 10px;
  }
  
  .controls {
    display: grid;
    grid-template-rows: 1fr;
    // margin: 15px -12px 22px -15px;
    padding: 15px 0;
    opacity: 1;
    transition: all 0.4s ease;
  }
  
  .collapsed {
    .controls {
      grid-template-rows: 0;
      overflow: hidden;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  
    .icon {
      transform: rotate(90deg);
    }
  }
  
  
  
  .header {
  
    &:hover {
      cursor: pointer;
    }
  }